import {defineStore} from "pinia";

export const store = defineStore({
    id: 'store',
    state: () => ({
        notifications:[],
    }),
    actions: {
        setNotify(type, message){
            this.notifications.push({
                text: message,
                type: type === 'error' ? 'Ошибка' : 'Успешно',
                time: type === 'error' ? 5000 : 2000,
                color: type === 'error' ? 'red' : 'green',
            })
        }
    }
});