import { createRouter, createWebHistory } from "vue-router";
// import Login from "@/pages/LoginPage.vue";
// import ProjectPage from "@/pages/ProjectPage.vue";
import GraphPage from '@/pages/GraphPage.vue'
// import { useAuthStore } from "@/store/auth";

const routes = [
  // {
  //   path: "/:pathMatch(.*)*",
  //   redirect: "/graph",
  // },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: Login,
  // },
  {
    path: "/",
    name: "openLK",
    // component: ProjectPage,
  },  
  {
    path: "/graph",
    name: "graph",
    component: GraphPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

// router.beforeEach((to, from, next) => {
//   const authStore = useAuthStore();
//   if (to.name !== "login" && !authStore.getToken()) {
//     next({ name: "login" }); // если не зареган и переход вне login
//   } else if (to.name === "login" && authStore.getToken()) {
//     next({ name: "projects" }); // если зареган и переход на /login
//   } else next();
// });
