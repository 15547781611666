<template>
    <div class="formation">
        <div class="formation__button" @click="selectPanel = !selectPanel">
            <img style="height:3vh; width: 3vh;" :src="require(`@/assets/icons/${activeFormation}.svg`)" alt="">
        </div>
        <div v-if="selectPanel" class="formation__select-panel">
            <div  class="formation__item"
                v-for="(formation,index) in formations" 
                :key="index"
                @click="setFormation(formation)"
                >
                <div class="formation__button">
                    <img style="height:3vh; width: 3vh;" :src="require(`@/assets/icons/${formation}.svg`)" alt="">
                </div>
                <p>{{ formation }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props:['formations', 'activeFormation'],
    data(){
        return {
            selectPanel: false,
        }
    },
    methods:{
        setFormation(val){
            this.$emit('change-formation', val)
            this.selectPanel = !this.selectPanel
        }
    }

}
</script>

<style lang="scss">
.formation{
    width: 5vh;
    height: 5vh;
    position: relative;
    &__button{
        cursor: pointer;
        height:5vh; 
        width: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__select-panel{
        position: absolute;
        right: 6vh;
        top: 0;
        min-width: 20vh;
        min-height: 5vh;
        background-color: white;
        border-radius: 20px;
        padding: 0.5vh;
    }
    &__item{
        display: flex;
        cursor: pointer;
        border-radius: 20px;
        justify-content: flex-start;
        align-items: center;

    }
}
.formation__item:hover{
    background-color: rgba(26, 100, 212, 0.2);
}
</style>