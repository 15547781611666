import router from '@/router/index';
import { useLK } from './LKstore';

export function moduleInit(module,path){
    const LK = useLK()
    window.addEventListener('SetProject',(event) =>{
        LK.project = event.detail
    });
    window.addEventListener('ChangeLoadModule',(event) =>{
        LK.loadApp = event.detail
    });
    window.addEventListener('ChangeShowModule',(event) =>{
        LK.showContent = event.detail
      if(event.detail){
        router.push(path)
      } else {
        router.push('/')
      }
    });
    window.addEventListener('SetUser',(event) =>{
      LK.user = event.detail
    });
    const event = new CustomEvent("SetModule", {detail: module});
    window.dispatchEvent(event);
  }