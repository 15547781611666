<script>
import clothGraph from "@/components/graph/clothGraph.vue";
import graphHeader from "@/components/graph/graphHeader.vue";
import {useGraphStore} from '@/store/dataGraph';
import {useAuthStore} from '@/store/auth'
import GraphPanelAction from "@/components/graph/graphPanelAction.vue";
import graphInfoPanel from '@/components/graph/graphInfoPanel.vue'
import { ref } from "vue";
export default {
  components:{ clothGraph, graphHeader, GraphPanelAction, graphInfoPanel },
  data(){
    return {
      cardType:'',
      oneManMode:false,
      activeGraph: '',
      loaded:'',
      formation:['force','сircular','concentric','parallel','dagre','elk'],
      activeFormation:'force',
      testUserIds:
      [
        'cdr228',
        // 'cronoros',
        // 'pprrivett'
        // FB 465803052217681
        // nsidachev
        // insta
        // g_o_dllllrio
        // maksimizg
        // lina_ftsv
        // fb seregashab
        // vk 120802124
      ],
      card:'',
      data:'',
      dataGraphs:'',
    }
  },
  setup(){
    // this.activeGraph = this.testUserIds[0]
    const graphStore = useGraphStore()
    const authStore = useAuthStore()
    const NODES = ref([])
    const EDGES = ref([])
    return {
      graphStore,
      authStore,
      NODES,
      EDGES
    }
  },
  async mounted(){
    // for(let i = 0;i<this.testUserIds.length;i++){
      // this.loaded = false
      //  await this.graphStore.getUserFriends('cronoros')
        
      //  await this.graphStore.getUserFriends('cronoros')
    //  await this.loadDataInsta('g_o_dllllrio') 

      // this.loaded = true
      
      // dataGraphs[this.testUserIds[0]] =  await this.graphStore.getUserFriends(this.testUserIds[0])

    // }
  },
  methods:{
    async loadDataVK(id){
      const newData = await this.graphStore.getUserFriendsVK(id)
      this.setData(newData)

    },
    async loadDataFB(id){
      const newData = await this.graphStore.getUserFriendsFB(id)
      this.setData(newData)
    },
    async loadDataInsta(id){
      const newData = await this.graphStore.getUserFriendsInsta(id)
      this.setData(newData)
    },
    setData(data){
      console.log(data)
      this.loaded = false
      if(!this.oneManMode && data  ){
        console.log('only one',data)
        data.NODES[0].KING = true
        // console.log(data.NODES[0])
        this.EDGES.value = data.EDGES
        this.NODES.value = data.NODES
      } else if(data){
        console.log('one man',data)
        data.NODES[0].KING = true
        this.EDGES.value = this.EDGES.value.concat(data.EDGES)
        this.NODES.value = this.NODES.value.concat(data.NODES)
        console.log(this.NODES)
      }
      console.log(this.NODES, this.EDGES)
      if(data && this.NODES.value[0] ){
        setTimeout(()=>{
          this.loaded = true
          console.log(this.loaded)
        },100)

      } else if(this.oneManMode){
        setTimeout(()=>{
          this.loaded = true
          console.log(this.loaded)
        },100)
      }
    },
    setFormation(val){
      // this.loaded = false
      this.activeFormation = val
      // setTimeout(()=>{
      //   this.loaded = true
      // },100)
    },
    async loadCard(node){
      console.log(node)
      if(!node) return
      this.cardType = node.type
      if(node.type === "VK"){
        this.card = await this.graphStore.getUserDataVK(node.id)
      } else if(node.type === "FB"){
        this.card = await this.graphStore.getUserDataFB(node.id)
      } else {
        this.card = await this.graphStore.getUserDataInsta(node.id)
      }
      console.log(this.card)
    },
    handleClickGraph() {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else { // старый IE
        document.selection.empty();
      }
    }
  }
}
</script>

<template>
  <div style="height: 93vh;width: 100%;position:relative;">
    <graphHeader @one-man="(val)=> oneManMode = val" @findInsta="(id)=>loadDataInsta(id)" @findFB="(id)=>loadDataFB(id)" @findVK="(id)=>loadDataVK(id)" :data="testUserIds" :active="activeGraph" @set-active="(id)=>activeGraph = id" />
    <clothGraph v-if="loaded" @select-node="(val)=>loadCard(val)" :activeFormation="activeFormation" :NODES="NODES.value" :EDGES="EDGES.value" @click="handleClickGraph()"/>
    <GraphPanelAction v-if="loaded" :formation="formation" @change-formation="(val)=>setFormation(val)" :activeFormation="activeFormation" />
    <graphInfoPanel v-if="card" :type="cardType" :card="card" />
  </div>
</template>
