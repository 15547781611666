export default class Crud {
  constructor(endpoint, idField = "id") {
    this.state = {
      endpoint,
      idField,
    };
    this.actions = {
      setError(err) {
        this.error = err;
      },
      cleanError() {
        this.error = null;
      },
    };
  }
}
