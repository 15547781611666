import { defineStore } from "pinia";
import {store} from './index'
import axios from "axios";
// import {useAuthStore} from '@/store/auth'

//friendshipGraph
export const useGraphStore = defineStore({
    id: 'dataGraph',
    state: () => ({
        FBloader:false,
        VKloader:false,
        INSTAloader:false,
    }),
    actions: {
        async getUserFriendsFB(userId){
            try{
                //,{headers: {Authorization: "Bearer " + useAuth.getToken()}}
                const preRes = await axios.post(
                    `/facebook/api/Management/CreateSocialGraph`,
                    {
                        "from_point": userId,
                        "depth_level": 1,
                        "social_amount": 100
                      })
                console.log(preRes)
                this.FBloader = true
                return await this.awaitDataFB(preRes.data)

            } catch(e){
                this.FBloader = false                
                console.log(e)
            }
        },
        async awaitDataFB(userId){
            const Store = store()
            try{
                const data = {
                    "socialGraphId": userId,
                    "canUnfinished": false
                }
                //465803052217681
                console.log(data)
                const res = await axios.get(`/facebook/api/Management/GenerateSocialGraphAsJson?socialGraphId=${userId}&canUnfinished=false`, data)
                console.log(res.data)
                if(typeof res.data  != 'string'){
                    console.log(res.data,typeof res.data )
                    this.FBloader = false
                    res.data.Profiles.forEach(element => {
                        element.type = "FB"
                    });
                    const result = {NODES:res.data.Profiles, EDGES:res.data.Friendships,}
                    console.log(this.checkData(result.NODES))
                    if(this.checkData(result.NODES)) return result
                    return undefined
                } else if(res.data == "Not completed."){ 

                    // return setTimeout(async ()=>{
                        return await this.awaitDataFB(userId)
                    // }, 5000)
                } else {
                    this.FBloader = false
                    Store.setNotify('error', "Невозможно отобразить результат")
                }
            } catch(e){
                console.log(e)
                this.FBloader = false
                Store.setNotify('error', "Что то пошло не так")
            }
        },
        async getUserFriendsVK(userId){
            // const useAuth = useAuthStore()
            try{
                //,{headers: {Authorization: "Bearer " + useAuth.getToken()}}
                this.VKloader = true
                const preRes = await axios.get(`/vk/FriendshipProfile/friendshipsAndProfiles/${userId}/json`)
                console.log(preRes)
                return await this.awaitDataVK(userId)
            } catch(e){
                console.log(e)
            }
        },
        async awaitDataVK(userId){
            const Store = store()
            try{
                const res = await axios.get(`/vk/FriendshipProfile/result/${userId}/json`)
                console.log(res.data)
                console.log(1,typeof res.data  != 'string',res.data)
                console.log(2,res.data == "in processing...",res.data)
                if(typeof res.data  != 'string'){
                    res.data.Profiles.forEach(element => {
                        element.type = "VK"
                    });

                    const uniqueEdges = []

                    res.data.Friendships.forEach(el => {
                        const exists = uniqueEdges.some(edge => {
                            return edge.source === el.source && edge.target === el.target
                        })
                    
                        if (!exists) {
                            uniqueEdges.push(el)
                        }
                    });

                    const result = {NODES:res.data.Profiles, EDGES:uniqueEdges  }
                    this.VKloader = false
                    if(this.checkData(result.NODES)) return result
                    return undefined
                } else if(res.data === 'in processing...'){ 
                    console.log('in func')
                    // const rec = setTimeout(async ()=>{
                        return await this.awaitDataVK(userId)
                    // }, 5000)
                    // return await rec
                } else {
                    this.VKloader = false
                    Store.setNotify('error', "Невозможно отобразить результат")
                }
            } catch(e){
                console.log(e)
                this.VKloader = false
                Store.setNotify('error', "Что то пошло не так")
            }
        },
        async getUserFriendsInsta(userId){
            try{
                this.INSTAloader = true
                const profile = await this.getProfileDataInsta(userId)
                if(profile){
                    const preRes = await axios.post(
                        `/insta/api/Management/CreateSocialGraph`,
                        {
                            "from_user_id": profile.id,
                            "depth_level": 1,
                            "social_amount": 100
                          })
                    console.log(preRes)
                    return await this.awaitDataINSTA(preRes.data)
                }


            } catch(e){
                this.INSTAloader = false                
                console.log(e)
            }
        },
        async awaitDataINSTA(userId){
            const Store = store()
            try{
                const data = {
                    "socialGraphId": userId,
                    "canUnfinished": false
                }
                //465803052217681
                console.log(data)
                const res = await axios.get(`/insta/api/Management/GenerateSocialGraphAsJson?socialGraphId=${userId}&canUnfinished=false`, data)
                console.log(1,typeof res.data  != 'string',res.data)
                console.log(2,res.data == "Not completed.",res.data)
                if(typeof res.data  != 'string'){
                    console.log(res.data,typeof res.data )
                    this.INSTAloader = false
                    res.data.Profiles.forEach(element => {
                        element.type = "insta"
                    });
                    const result = {NODES:res.data.Profiles, EDGES:res.data.Friendships,}
                    if(this.checkData(result.NODES)) return result
                    return undefined
                } else if(res.data == "Not completed."){ 
                    // return setTimeout(async ()=>{
                        return await this.awaitDataINSTA(userId)
                    // }, 5000)
                } else {
                    this.INSTAloader = false
                    Store.setNotify('Нет данных', "Невозможно отобразить результат")
                }
            } catch(e){
                console.log(e)
                this.INSTAloader = false
                Store.setNotify('error', "Что то пошло не так")
            }
        },
        async getUserDataVK(id){
            const Store = store()
            try{
                const res = await axios.get(`/vk/Profile/publicOptionalProfile/${id}`)
                console.log(res.data.response[0])
                if(res.data.response[0]){
                    return res.data.response[0]
                } else {
                    Store.setNotify('error', "Не удалось загрузить профиль")
                }
            } catch(e){
                console.log(e)
                Store.setNotify('error', "Не удалось загрузить профиль")
            }
        },
        async getUserDataFB(id){
            const Store = store()
            try{
                const res = await axios.get(`/facebook/api/Management/GetUserProfile?fbId=${id}`)
                console.log(res.data)
                if(typeof res.data === 'string'){
                    return await this.awaitGetUserDataFB(res.data)
                } else {
                    console.log(res.data)
                    return res.data
                }
            } catch(e){
                console.log(e)
                Store.setNotify('error', "Не удалось загрузить профиль")
            }
        },
        async getProfileDataInsta(id){
            const Store = store()
            try{
                const res = await axios.get(`/insta/api/Insta/user?username=${id}`)
                console.log(res.data)
                return res.data
            } catch(e){
                console.log(e)
                Store.setNotify('error', "Не удалось собрать данные")
            }
        },
        async getUserDataInsta(id){
            const Store = store()
            try{
                const res = await axios.get(`/insta/api/Insta/user/by_id?user_id=${id}`)
                console.log(res.data)
                return res.data
            } catch(e){
                console.log(e)
                Store.setNotify('error', "Не удалось собрать данные пользователя")
            }
        },
        async awaitGetUserDataFB(uid){
            const Store = store()
            try{
                const res = await axios.get(`/facebook/api/Management/TryGetUserProfile?taskId=${uid}`)
                console.log(res.data)
                if(typeof res.data === 'string'){
                    return await this.awaitGetUserDataFB(uid)
                } else {
                    console.log(res.data)
                    return res.data
                }
            } catch(e){
                Store.setNotify('error', "Не удалось загрузить профиль")
            }
        },
        checkData(nodes){
            const Store = store()
            if(!nodes[0]){
                console.log('fsdfdsfsdf', nodes[0])
                Store.setNotify('Нет данных', "Невозможно отобразить результат")
                return false
            }
            return true
        }
    },
})