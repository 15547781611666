import { defineStore } from "pinia";
import Crud from "@/store/crud";
import axios from "axios";
// import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import {mainStorage} from '@/tools/main-storage.tools'

// const $toast = useToast();

const crud = new Crud("/globus/api/Auth", "id");
export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    username: "",
    password: "",
    ...crud.state,
    test: null,
  }),
  getters: {},
  actions: {
    ...crud.actions,
    setToken(token) {
      mainStorage.setItem("access_token", token);
    },
    getToken() {
      return mainStorage.getItem("access_token");
    },
    setRefreshToken(token) {
      mainStorage.setItem("refresh_token", token);
    },
    getRefreshToken() {
      return mainStorage.getItem("refresh_token");
    },
    delToken() {
      mainStorage.removeItem("access_ttoken");
    },
    delRefreshToken() {
      mainStorage.removeItem("refresh_token");
    },
    // async doLogin(username, password) {
    //   const data = {
    //     username,
    //     password,
    //   };
    //   try {
    //     console.log(this.endpoint + "/login")
    //     const response = await axios.post(this.endpoint + "/login", data);
    //     if (!response?.data?.access_token) {
    //       throw "Unable to get token";
    //     }
    //     this.setToken(response.data.access_token);
    //     this.setRefreshToken(response.data.refresh_token);
    //     this.cleanError();
    //   } catch (err) {
    //     this.setError(err);
    //     const errorStatus = err.response?.status;
    //     if (errorStatus === 401) {
    //       $toast.open({
    //         message: "Неправильный логин или пароль",
    //         type: "error",
    //         duration: 3000,
    //       });
    //     }
    //   }
    // },

    async doRefreshToken() {
      console.log(crud)
      const url = crud.state.endpoint + "/refresh";
      const data = {
        refresh_token: this.getRefreshToken(),
      };
      try {
      //  crud.loading.value = true;
        const response = await axios.post(url, JSON.stringify(data, null, 4), {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response?.data?.access_token) {
          throw "Unable to get token";
        }
        console.log('refresh',response.data)
        this.setToken(response.data.access_token);
        this.setRefreshToken(response.data.refresh_token);
        crud.actions.clearLoading();
      } catch (e) {
        crud.actions.setError(e);
      }
    },
  },
});
