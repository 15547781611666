<template>
    <div class="panel-info-container">
        <div class="info-item">
            <p  class="info-text">ID</p>
            <p  class="info-text">{{ card.id }}</p>
        </div>
        <div class="info-item">
            <p  class="info-text">Имя</p>
            <p  class="info-text">{{ card.full_name }}</p>
        </div>
        <div class="info-item" v-if="card.biography">
            <p  class="info-text">О себе</p>
            <p  class="info-text">{{ card.biography }}</p>
        </div>
        <div class="info-item" v-if="card.followed_by_count">
            <p  class="info-text">Подписчики</p>
            <p  class="info-text">{{ card.followed_by_count }}</p>
        </div>
        <div class="info-item" v-if="card.follows_count">
            <p  class="info-text">Подписки</p>
            <p  class="info-text">{{ card.follows_count }}</p>
        </div>
        <div class="info-item" v-if="card.posts_count" >
            <p  class="info-text">Колличество постов</p>
            <p  class="info-text" :href="card.posts_count">{{ card.posts_count }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props:['card'],
    created(){
        console.log(this.card)
    }

}
</script>