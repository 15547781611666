<script>
import {useGraphStore} from "@/store/dataGraph"
import localLoader from '@/components/common/localLoader.vue'
export default {
    props:['data', 'active'],
    data(){
        return {
            theme: 'Светлая',
            findIdVK:'',
            findIdFB:'',
            findInsta:'',
            graphStore: useGraphStore()
        }
    },
    components:{localLoader},
    watch:{
        theme(val){
            console.log(val, document.classList)
            const element = document.querySelector('.unovis-single-container');
            const className = element.className;
            const body = document.querySelector('body')
            console.log(className)
            if(val === 'Тёмная'){
               
                body.classList.add('theme-dark')
                element.style["background-color"] = 'black'
            } else {
                body.classList.remove('theme-dark')
                element.style["background-color"] = 'rgba(238, 241, 244, 1)'
            }
        }
    }
}
</script>

<template>
            <!-- <div v-for="id in data" 
            :key="id"  
            class="header__graph" 
            @click="active != id ? $emit('set-active', id) : ''"
            :style="active === id ? 'background-color:rgba(224, 240, 255, 1);color:rgba(31, 154, 255, 1);' : 'color: black;background-color: white;'" 
            >
            {{ id }}
        </div> -->
<div class="header__cont">
    <div style="display: flex;">
        <div style="display: flex;align-items: center;margin-right: 3vh;">
            <p class="text">Поиск по ID Вконтакте:</p>
            <input class="header__find-input text" v-model="findIdVK" type="text">
            <div class="text header__find-button"  @click="$emit('findVK', findIdVK)">Найти</div>
            <localLoader v-if="graphStore.VKloader" />
        </div>
        <div style="display: flex;align-items: center;margin-right: 3vh;">
            <p class="text">Поиск по ID Facebook:</p>
            <input class="header__find-input text" v-model="findIdFB" type="text">
            <div class="text header__find-button"  @click="$emit('findFB', findIdFB)">Найти</div>
            <localLoader v-if="graphStore.FBloader" />
        </div>
        <div style="display: flex;align-items: center;margin-right: 3vh;">
            <p class="text">Поиск по ID Instagram:</p>
            <input class="header__find-input text" v-model="findInsta" type="text">
            <div class="text header__find-button"  @click="$emit('findInsta', findInsta)">Найти</div>
            <localLoader v-if="graphStore.INSTAloader" />
        </div>
        <div style="display: flex;align-items: center;">
            <p class="text" style="margin-right: 1vh;">Просмотр одного человека</p>
            <input type="checkbox" @click="(e)=>$emit('one-man', e.target.value)">
        </div>
    </div>

    <div class="change-sheme-btn">
        <div 
            class="header__graph text" 
            @click="theme = theme === 'Светлая' ? 'Тёмная' : 'Светлая'"
            :style="theme !== 'Светлая' ? 'background-color:rgba(224, 240, 255, 1);color:rgba(31, 154, 255, 1);' : 'color: black;background-color: white;'">
            {{ theme === 'Светлая' ? 'Тёмная' : 'Светлая' }}
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
.change-sheme-btn{
    margin-right: 1vh;
}
.header {
    &__cont{
        height: 5vh;
        width: 99%;
        padding-left: 50px;
        border-radius: 10px;
        background-color: white;
        margin: 1vh;
        box-sizing: border-box;
        position: absolute;
        display: flex;
        justify-content: space-between;
        // border: 0.5px solid gray;
        box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.3);

    }
    &__graph{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-width: 3vh;
        padding: 5px;
        box-sizing: border-box;
        cursor: pointer;
    }
    &__find-input{
        height: 50%;
        width: 20vh;
        border: 1px solid gray;
        height: 3vh;
        padding-left: 10px;
    }
    &__find-button{
        background-color: blue;
        color: white;
        width: 7vh;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 20px 20px 0;
        height: 3vh;
        cursor: pointer;
    }
}
.text{

    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

}
</style>