<template>
    <div class="panel-action-container">
        <graphButtonFormation 
            :formations="formation" 
            @change-formation="(val)=>$emit('change-formation',val)" 
            :activeFormation="activeFormation" />
    </div>
</template>

<script>
import graphButtonFormation from './graphButtonActionFormation.vue'
export default {
    props:['formation', 'activeFormation'],
    components:{graphButtonFormation},
}
</script>

<style>
.panel-action-container{
    position: absolute;
    right: 1%;
    top: 10vh;
    border-radius: 20px;
    background-color: white;
    min-height: 5vh;
    width: 5vh;
    box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.3);
}
</style>