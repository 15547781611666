<template>
    <div class="graph">
        <VisSingleContainer :data="data" height="93vh" :svgDefs="ICONS">
            <VisGraph
                :nodeShape="getNodeImage"
                :layoutType="activeFormation"  
                :linkArrow="linkArrow" 
                :forceLayoutSettings="forceLayoutSettings" 
                :nodeSize="getSize" 
                :nodeFill="getNodeColor" 
                :linkStroke="'#BDC3D1'"
                :linkWidth="2"
                :events="events"
                :selectedNodeId="selectedNode"
                :nodeLabel="getLabel"
                :nodeLabelTrim="true"
                 />
                 <!-- :nodeStrokeWidth="sizeHover"  -->
        </VisSingleContainer>
    </div>
</template>

<script>
import { VisSingleContainer, VisGraph } from '@unovis/vue'
import { Graph } from '@unovis/ts'
import { store } from '@/store/index.js'
import { iconNodes } from "@/consts/iconsSocial"
export default {
    components:{VisSingleContainer, VisGraph},
    setup() {
    },
    props: [
        'NODES',
        'EDGES',
        'activeFormation',
        // ''
    ],
    data() {
        return {
            Store: store(),
            selectedNode:'',
            GRAPH: '',
            data: '',
            forceLayoutSettings:'',
            events: '',
            ICONS:''
            //     [Graph.selectors.node]: {
            //         click: (d) => {
            //             console.log(d)
            //         // Set the selected node id here, e.g.: config.selectedNodeId = d.id
            //         // and trigger the component update if required by your UI framework
            //         }
            //     },
            // }
        }
    },
    watch:{
        selectedNode(val){
            console.log(val)
            const node = this.NODES.find(item => item.id === val)
            this.$emit('select-node',node)
        }
    },
    methods: {
        initGraph() {
            this.ICONS = iconNodes
            try{
                this.NODES.forEach(node=>{
                    node.id = `${node.id}`
                })
                this.EDGES.forEach(link=>{
                    link.target = `${link.target}`
                    link.source = `${link.source}`
                })
                // console.log(this.NODES, this.EDGES)
                this.forceLayoutSettings = {
                    disableDrag:false,
                    forceXStrength: 0.5,
                    forceYStrength: 0.5,
                    charge: -700,
                    zoomScaleExtent:[0.01, 10]
                }
                this.data = { nodes: this.NODES, links: this.EDGES}
                this.events = {
                    [Graph.selectors.background]: {
                        click: ()=>{
                            console.log('back')
                            this.selectedNode = null
                        }
                    },
                    [Graph.selectors.node]: {
                        click: (d) => {
                            console.log(d, 'node')
                            this.selectedNode = d.id
                        // Set the selected node id here, e.g.: config.selectedNodeId = d.id
                        // and trigger the component update if required by your UI framework
                        }
                    },
                }
            } catch (e){
                console.log(e)
                this.Store.setNotify('error','Что то пошло не так')
            }


           
        },
        // sizeHover(node){

        // },
        getLinkStyle(l){
            console.log('style',l)
        },
        getNodeColor(node) {
            // console.log('color', node)
            if (node.KING) return 'red'

            return 'blue'
        },
        getSize(node) {
            // console.log( node)
            if (node.KING) return 100
            return 50
        },
        getLabel(d){
            // console.log(d)
            return d.first_name + ' ' + d.last_name
        },
        linkArrow(l){
            // console.log('test', l)
            if(this.EDGES.find(item=> item.target === l.source && item.source === l.target)){
                return 'double'
            }
            return 'single'
        },
        getNodeImage(node){
            return `<use href = "#${node.type}" width="${this.getSize(node)}" height="${this.getSize(node)}" / >` 
        }

    },
    mounted() {
       if(this.NODES && this.NODES[0]) this.initGraph()
    }
}
</script>

<style lang="scss" >
.unovis-single-container{
    background-color: rgba(238, 241, 244, 1);
}
.graph {
    height: 100%;
    width: 100%;

    &__window {
        height: 100%;
        width: 100%;
    }
}

</style>