import axios from "axios";
import environment from "@/environment";
// import { useAuthStore } from "@/store/auth";
import { useLK } from "@/controllerLK/LKstore";
// import router from "@/router/index.js";

function isMyUrl(url) {
  console.log(url)
  return url.toLowerCase().indexOf(environment.appConfig.baseApiUrl) === 0
    ? false
    : true;
}

export async function axiosInit() {
  const lk = useLK()
  // const authStore = useAuthStore();
  axios.interceptors.request.use(async (config) => {
    if (isMyUrl(config.url) && config.url.indexOf("/login") < 0) {
      config.headers.Authorization = "Bearer " + await lk.getToken();
    }
    return config;
  });
  // axios.interceptors.response.use(null, async (error) => {
  //   if(error.response?.status === 401){
  //     await lk.getToken();
  //     return  axios.request(error.config);
  //   }
  //   if(error.response?.status === 403  || error.response?.status === 500 || error.response?.status === 405){
  //     return 
  //   } 


     
  // });
}
