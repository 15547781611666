<template>
    <div >
        <infoPanelVk v-if="type === 'VK'" :card="card" />
        <infoPanelFb v-if="type === 'FB'" :card="card" />
        <infoPanelInsta v-if="type === 'insta'" :card="card"/> 
        <!-- type === 'insta' -->
    </div>
</template>

<script>
import infoPanelVk from '@/components/graph/infoPanels/infoPanelVk.vue'
import infoPanelFb from '@/components/graph/infoPanels/infoPanelFb.vue'
import infoPanelInsta from '@/components/graph/infoPanels/infoPanelInsta.vue'
export default {
    components:{infoPanelVk, infoPanelFb,infoPanelInsta},
    props:['card', 'type'],
    created(){
        console.log(this.card)
    }

}
</script>

<style lang="scss">
@import "@/assets/style/style.scss";
.panel-info-container{
    position: fixed;
    right: 1%;
    bottom: 1%;
    border-radius: 20px;
    background-color: white;
    min-height: 15vh;
    min-width: 45vh;
    padding: 4vh;
    box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.3);
}

.info-item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
    
}

.info-text{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    text-wrap: wrap;
    margin-right: 1vh;

}
</style>