
<template>
    <div class="notify">
        <p :style="'color:'+notify.color+';'" class="notify__type">{{ notify.type }}</p>
        <p class="notify__text">{{ notify.text }}</p>
    </div>
</template>

<script>
export default {
    props:['notify'],
    mounted(){
        setTimeout(()=>{
            this.$emit('del-not', this.notify.text)
        }, this.notify.time)
    }
}
</script>

<style lang="scss">
@import "@/assets/style/style.scss";
.notify{
    margin-bottom: 1vh;
    width: 100%;
    padding: 1vh;
    min-height: 5vh;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 1px 8px 0px rgba(34, 60, 80, 0.3);
    &__type{
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;

    }
    &__text{
        margin-top: 1vh;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
    }

}
</style>