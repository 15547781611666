<template>
    <div class="notification-container">
        <notify v-for="(item, index) in Store.notifications" @del-not="(text)=>delNotify(text)" :key="index" :notify="item"/>
    </div>
</template>

<script>
import notify from './notify-item.vue';
import {store} from '@/store/index.js';
export default {
    components:{notify},
    data(){
        return {
            Store: store(),
        }
    },
    methods:{
        delNotify(text){
            console.log(this.Store)
            this.Store.notifications = this.Store.notifications.filter(item=>item.text != text)

        }
    }
}
</script>

<style>
.notification-container{
    position: fixed;
    z-index: 1000;
    right: 1%;
    bottom: 1%;
    width: 20%;
    min-height: 5vh;

}
</style>