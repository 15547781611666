<template>
    <div class="panel-info-container">
        <div class="info-item">
            <p  class="info-text">ID</p>
            <p  class="info-text">{{ card.fb_id }}</p>
        </div>
        <div class="info-item">
            <p  class="info-text">Имя</p>
            <p  class="info-text">{{ card.name }}</p>
        </div>
        <div class="info-item" v-if="card.about">
            <p  class="info-text">О себе</p>
            <p  class="info-text">{{ card.about }}</p>
        </div>
        <div class="info-item" v-if="card.followers_count">
            <p  class="info-text">Подписчики</p>
            <p  class="info-text">{{ card.followers_count }}</p>
        </div>
        <div class="info-item" v-if="card.following_count">
            <p  class="info-text">Подписки</p>
            <p  class="info-text">{{ card.following_count }}</p>
        </div>
        <div class="info-item" v-if="card.url" >
            <p  class="info-text">Ссылка на профиль</p>
            <a  class="info-text" :href="card.url">{{ card.url }}</a>
        </div>
        <div class="info-item" v-if="card.intro && card.intro[0]">
            <p class="info-text">Возможно полезная информация</p>
            <div style="display: flex;flex-direction: column;align-items: flex-end;">
                <p v-for="(info,index) in card.intro" :key="index" class="info-text">{{info}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['card'],
    created(){
        console.log(this.card)
    }

}
</script>