<template>
  <div class="main" v-if="LK.loadApp" v-show="LK.showContent">
    <router-view />
    <notifications/>
  </div>
</template>

<script>
import notifications from '@/components/notifications/notifications-container.vue'
import {moduleInit} from '@/controllerLK/initLK'
import {useLK} from "@/controllerLK/LKstore"
export default {
  components:{notifications},
  data(){
    return {
      LK: useLK(),
    }
  },
  created(){
    moduleInit('module_visualizer','/graph')
  },

}
</script>

<style lang="scss">
@import "@/assets/style/vars.scss";
.main {
  min-width: $md3;
}
.custom-sankey {
  --vis-sankey-node-color: #4D8CFD;
  --vis-dark-sankey-node-color: #FF6B7E;
  --vis-sankey-link-color: lightgray;
  --vis-dark-sankey-link-color: #212;
}
</style>
