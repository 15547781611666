<template>
    <div class="panel-info-container">
        <div class="info-item">
            <p  class="info-text">ID</p>
            <p  class="info-text">{{ card.id }}</p>
        </div>
        <div class="info-item" v-if="card.first_name && card.last_name">
            <p  class="info-text">Фамилия и имя:</p>
            <p  class="info-text">{{card.first_name + ' ' + card.last_name}}</p>
        </div>
        <div class="info-item" v-if="card.bdate">
            <p  class="info-text">Дата рождения:</p>
            <p  class="info-text">{{ card.bdate }}</p>
        </div>
        <div class="info-item" v-if="card.counters && card.counters.followers">
            <p  class="info-text">Подписчики:</p>
            <p  class="info-text">{{card.counters.followers}}</p>
        </div>
        <div class="info-item" v-if="card.counters && card.counters.friends">
            <p  class="info-text">Друзья:</p>
            <p  class="info-text">{{card.counters.friends}}</p>
        </div>
        <div class="info-item" v-if="card.counters && card.counters.photos">
            <p  class="info-text">Фото:</p>
            <p  class="info-text">{{ card.counters.photos }}</p>
        </div>
        <div class="info-item" v-if="card.counters && card.counters.videos">
            <p class="info-text">Видео:</p>
            <p class="info-text">{{ card.counters.videos }}</p>
        </div>

    </div>
</template>

<script>
export default {
    props:['card'],
    created(){
        console.log(this.card)
    }

}
</script>